.cards {
    @extend .container;
    padding-top: 2em;
    padding-bottom: 0;
    margin-top: 2em;
    @include respond-to(medium) {
        margin-top: 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;
    }
}

.card {
    border: 1px solid palette(primary);
    border-radius: 6px;
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    width: 75vw;
    @include respond-to(medium) {
      flex: 1 30%;
      margin-left: 1em;
      margin-right: 1em;
      margin-bottom: 0;
      &:first-child {
          margin-left: 0;
      }
      &:last-child {
          margin-right: 0;
      }
    }
    header {
        background: palette(primary);
        padding: .5em 1em;
        border-top-left-radius: 4px; // needs to be smaller than the .card radius
        border-top-right-radius: 4px;
        h2 {
            color: $white;
            text-align: center;
            font-size: 1.25em;
            font-weight: normal;
            margin: 0;
            @include respond-to(small) {
                font-size: 1.5em;
            }
        }
    }
}

.card__body {
    border-bottom-left-radius: 6em;
    border-bottom-right-radius: 6em;
    ul {
        margin: 0;
        padding: 1em;
        list-style: none;
        li {
            padding-bottom: .75em;
            font-size: .85em;
            @include respond-to(small) {
                font-size: 1em;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    img {
        max-width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    iframe {
          width: 100%;
          max-width: 100%;
          display: block;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
    }
}
