input {
  margin-top: 1em;
  margin-bottom: 1em;
  border: 0;
  background: #eff1f3;
  width: 50%;
}

textarea {
  margin-top: 1em;
  margin-bottom: 1em;
  border: 0;
  background: #eff1f3;
  width: 50%;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: .85em;
  line-height: 1.5em;
  color: $black;
  opacity: .6;
}

input:-moz-placeholder,
textarea:-moz-placeholder { /* Firefox 18- */
  font-size: .85em;
  line-height: 1.5em;
  color: $black;
  opacity: .6;
}

input::-moz-placeholder,
textarea:-moz-placeholder {  /* Firefox 19+ */
  font-size: .85em;
  line-height: 1.5em;
  color: $black;
  opacity: .6;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-size: .85em;
  line-height: 1.5em;
  color: $black;
  opacity: .6;
}
