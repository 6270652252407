$sans: 'fira-sans-2', sans-serif;
$serif: 'lexia', Georgia, serif;

a {
  color: palette(primary, dark);
  text-decoration: none;
  transition: all .5s ease-in-out;
  &:hover {
    color: palette(primary);
  }
}

.centered {
  text-align: center;
}

$line-height: 24px;

h1 {
    font-weight: 700;
}

h2 {
    line-height: $line-height * 1.5;
    color: $black;
    font-family: $serif;
    font-weight: 700;
    @include respond-to(small) {
      line-height: $line-height * 2;
    }
}

h3 {
    line-height: $line-height * 1.5;
    color: $black;
    font-family: $serif;
    font-weight: 400;
    margin-bottom: 0;
}

h4 {
    line-height: $line-height;
    color: $black;
    font-family: $serif;
    font-weight: 400;
}

p {
    font: 600 16px/1.6 $sans;
    color: $black;
}
