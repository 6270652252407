%button {
  padding: $baseline__vertical;
  text-align: center;
  color: $white;
  display: inline-block;
  &:hover {
    color: $white;
    text-decoration: none;
  }
}

a.button {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
}

.button,
.button__primary {
  @extend %button;
  background: palette(primary);
  &:hover {
    background: palette(primary, dark);
  }
}

.button__primary--inverted {
  @extend %button;
  background: darken($primary, 20);
  &:hover {
    background: darken($primary, 40);
  }
}

.button--rounded {
    border-radius: 6px;
}

.button__group {
    &--centered {
        margin-left: auto;
        margin-right: auto;
    }
}

.button__book {
    display: block;
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25em;
    @include respond-to(medium) {
        margin-top: 0;
    }
}
