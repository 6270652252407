.container {
  padding-top: $baseline__vertical * 2;
  padding-bottom: $baseline__vertical * 2;
  padding-left: $baseline__vertical;
  padding-right: $baseline__vertical;
  @include respond-to(small) {
    padding-left: 0;
    padding-right: 0;
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond-to(medium) {
    max-width: 60em;
  }
  @include respond-to(large) {
    max-width: 70em;
  }
  &--no-padding {
    @extend .container;
    padding-top: 0;
    padding-bottom: 0;
  }
  &--no-padding-top {
    @extend .container;
    padding-top: 0;
  }
  &--no-padding-bottom {
    @extend .container;
    padding-bottom: 0;
  }
  &--light-padding {
    @extend .container;
    padding-top: $baseline__vertical;
    padding-bottom: $baseline__vertical;
  }
}

// .columns__three {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 4em;
//   margin-bottom: 4em;
//   text-align: center;
//   h5 {
//     margin-top: 0;
//     color: palette(primary);
//   }
//   div {
//     padding-left: 3em;
//     padding-right: 3em;
//   }
//   & div:first-child {
//     border-right: 1px solid $black;
//   }
//   & div:last-child {
//     border-left: 1px solid $black;
//   }
// }
