.amenities h4 {
  background: palette(primary);
  color: white;
  text-indent: 0.8em;
}

table {
  width: 100%;
  tr {
    @include respond-to(small) {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
    }
  }
  td {
    padding: 0.8em 1em;
    @include respond-to(small) {
      flex: 1 30%;
    }
  }
}
