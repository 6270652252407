.page__content {
    @include respond-to(small) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2em;
      margin-bottom: 2em;
    }
}

.content__header {
    margin-top: 0;
    margin-bottom: .5em;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 700;
}

.content__image {
    @include respond-to(small) {
      flex: 1;
    }
    img {
        max-width: 100%;
    }
}

.main__copy {
    @include respond-to(medium) {
        padding-right: 2em;
    }
}

.sidebar {
    @include respond-to(medium) {
        padding-left: 2em;
    }
}
