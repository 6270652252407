.details {
    display: flex;
    justify-content: space-around;
    background: palette(primary);
    color: $white;
    font-weight: 400;
}

.detail {
    line-height: 2em;
}
