.thumbs {
    margin-top: -4em;
    margin-bottom: 4em;
    @include respond-to(small) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-top: 4em;
    }
}

.thumb {
    @include respond-to(small) {
      flex: 0 1 20%;
      margin: 5px
    }
    img {
      max-width: 100%;
    }
}
