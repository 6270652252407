.list__inline {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0;
    margin-top: 1em;
    margin-bottom: 1em;
    @include respond-to(small) {
        display: inline-block;   
    }
  }
  &--centered {
    @extend .list__inline;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  &--wide {
    @extend .list__inline;
    li {
        @include respond-to(small) {
            padding: 0 2em;
        }
    }
  }
}
