@function colorAdjust($color, $adjustment, $base: 10) {
  @if $adjustment == dark {
    $color: darken($color, $base);
    @return desaturate($color, $base);
  } @else if $adjustment == darker {
    $multiplier: $base * 2;
    $color: darken($color, $multiplier);
    @return desaturate($color, $multiplier);
  } @else if $adjustment == darkest {
    $multiplier: $base * 4;
    $color: darken($color, $multiplier);
    @return desaturate($color, $multiplier);
  } @else if $adjustment == light {
    $color: lighten($color, $base);
    @return saturate($color, $base);
  } @else if $adjustment == lighter {
    $multiplier: $base * 2;
    $color: lighten($color, $multiplier);
    @return saturate($color, $multiplier);
  } @else if $adjustment == lightest {
    $multiplier: $base * 4;
    $color: lighten($color, $multiplier);
    @return saturate($color, $multiplier);
  }
}

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}

/// Choose a gray tone
/// - neutral
/// - cool
/// - warm
/// @access public
/// @param {Family} $graytone - Graytone family
/// @param {Number} $value - Value of graytone returned (word value)
/// @return {Color}
/// @requires $graytone
@function graytone($family, $tone) {
  @return map-get(map-get($graytones, $family), $tone);
}

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix($color, white, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix($color, black, $percentage);
}

/// Computes a top-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(black, nth(.12 .16 .19 .25 .30, $depth));

  @return 0 $primary-offset $blur $color;
}

/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(.24 .23 .23 .22 .22, $depth));

  @return 0 $primary-offset $blur $color;
}
