// Map of the viewport breakpoints
$small: 48em;
$medium: 62em;
$large: 75em;
// 
$breakpoints: (
    small: $small,
    medium: $medium,
    large: $large
);

$baseline__vertical: 24px;
