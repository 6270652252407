.listing__meta {
  background: palette(primary);
  text-align: center;
  margin-bottom: 6px;
  h3 {
    color: $white;
    font-size: 1.1em;
    line-height: 2em;
  }
}
