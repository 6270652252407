footer {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
    p {
        margin: 0;
        font-size: .75em;
        @include respond-to(small) {
            line-height: 4em;
        }
    }
}
