.hero {
    background: url('/assets/img/interior-03.jpg') center center no-repeat;
    background-size: cover;
    height: 60vh;
    position: relative;
    z-index: -1;
    overflow:hidden;
    @include respond-to(small) {
        height: 80vh;
    }
    @include respond-to(large) {
        height: 90vh;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .2);
    }
}
