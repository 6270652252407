.coa {
  @extend .container--no-padding-bottom;
  @include respond-to(small) {
    display: flex;
    justify-content: space-between;
  }
}

.house__coa {
  background: palette(primary);
  color: $white;
  border-radius: 6px;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
  margin-bottom: 2em;
  @include respond-to(small) {
    flex: 1;
    display: flex;
    justify-content: space-around;
    &:first-child {
      margin-right: 20px;
    }
    &:last-child {
      margin-left: 20px;
    }
  }
  h3, h4 {
    margin: 0;
    color: $white;
  }
}

.house__button {
  margin-top: 1em;
  @include respond-to(small) {
    margin-top: 0;
  }
}
