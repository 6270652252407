.columns {
    margin-top: 4em;
    margin-bottom: 4em;
    @include respond-to(small) {
        display: block;
    }
    @include respond-to(medium) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-flow: row;
        &.align-items--flex-start {
            align-items: flex-start;
        }
    }
    &--reverse-small {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-direction: column-reverse;
        &.align-items--flex-start {
            align-items: flex-start;
        }
        @include respond-to(medium) {
            flex-direction: row;
        }
    }
}

.column {
    @include respond-to(small) {
        padding-left: 2em;
        padding-right: 2em;
    }

    @include respond-to(medium) {
        flex: 1;
    }
}

// .columns__two {
//     @extend .columns;
// }

@for $i from 0 through 100 {
    .column__#{$i} {
        @include respond-to(medium) {
            width: $i * 1%;
        }
    }
}

.column__one-third {
    width: 30%;
}

.column__two-thirds {
    width: 60%;
}

*[class^="column__"] {
    img {
        max-width: 100%;
    }
}
