html { box-sizing: border-box; }
* { box-sizing: inherit; }

body {
  font: 600 16px $sans;
}

main > .container {
    padding-top: 0;
    @include respond-to(small) {
        padding-top: $baseline__vertical * 2;
    }
}

.inverted {
  padding: 4em 2em;
  margin-top: 4em;
  margin-bottom: 4em;
  background: $black;
  color: $white;
  text-align: center;
  strong,
  em {
    color: palette(primary);
  }
}
