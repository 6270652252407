// .nav--primary {
//   padding: 0;
//   margin: 0;
//   list-type: none;
//   text-align: center;
//   li {
//     display: inline;
//     padding-left: 1em;
//     padding-right: 1em;
//     a{
//       font-weight: 700;
//       font-family: $headline;
//       text-transform: uppercase;
//       color: $black;
//       &:hover {
//         color: primary;
//         text-decoration: none;
//       }
//       &:before {
//         content: '';
//       }
//     }
//   }
// }

body > header > nav {
    padding: 0;
    margin: 0;
    @include respond-to(small) {
        flex: 1;
    }
    @include respond-to(large) {
        padding: 24px 0;
    }
    // &:first-child {
    //     @include respond-to(small) {
    //         flex: 2;
    //     }
    // }
    // &:last-child {
    //     @include respond-to(small) {
    //         flex: 5;
    //     }
    // }
}

.nav--primary {
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
  // padding: 0 0 1em 1em;
  @include respond-to(small) {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: baseline;
  }
  @include respond-to(medium) {
    width: 100%;
    padding: 0;
  }

  li {
    flex: 1;
    font-size: 1.25em;
    line-height: 2em;
    font-weight: 600;
    font-family: $sans;
    @include respond-to(small) {
      display: block;
      font-size: .99em;
    }
    a{
      text-transform: uppercase;
      color: palette(primary);
      &:hover {
        color: palette(primary, darker);
        text-decoration: none;
      }
    }
    &:first-child{
      display: block;
      text-align: center;
    }
  }
}

.menu--toggle {
  text-transform: uppercase;
  @include respond-to(small) {
    display: none;
  }
}

.nav--secondary {
    text-align: right;
    @include respond-to(small) {
        // display: flex;
        // justify-content: flex-end;
        // align-content: flex-end;
        // align-items: baseline;
    }
    @include respond-to(medium) {
        flex: 0 1 10%;
    }
    li {
        display: inline-block;
        text-align: right;
        margin: 0;
        padding: 0 24px;
        list-style: none;

        flex: 1;
        @include respond-to(medium) {
            padding: 0 12px;
            line-height: 1.5em;
        }
        @include respond-to(large) {
            padding: 0 24px;
            line-height: 48px;
        }
    }
}

// .nav--secondary {
//   background: palette(primary, dark);
//   border-bottom: 1px solid palette(primary, darker);
//   position: relative; // for the search to work properly on mobile
//   &__inner {
//     margin: 0 auto;
//     @include respond-to(small) {
//       max-width: 64em;
//       display: flex;
//       justify-content: flex-end;
//       align-items: center;
//     }
//   }
//   nav {
//     padding-right: 1em;
//   }

//   ul {
//     // width: 64em;
//     list-style: none;
//     padding: 0;
//     margin: 0;
//     text-align: right;
//     display: none;
//     @include respond-to(small) {
//       display: block;
//     }
//   }
//   li {
//     display: inline;
//   }
//   a {
//     padding-left: 1em;
//     padding-right: 1em;
//     font: bold .75em/2.375em $sans;
//     color: palette(primary);
//     &:hover {
//       color: palette(primary, darker);
//     }
//     @include respond-to(small) {
//       color: palette(primary, light);
//       &:hover {
//         color: palette(primary, lightest);
//       }
//     }
//   }
// }

.nav--curtain {
  background: palette(primary, darker);
  border: none;
  max-height: 0;
  text-align: center;
  overflow: hidden;
  transition: max-height 2s .2s cubic-bezier(0.15, 0.68, 0.32, 1);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      &:first-child {
        display: none;
      }
      a {
        color: palette(primary, lightest);
        display: block;
        line-height: 2.5em;
      }
    }
  }
  &.active {
    max-height: 1200px;
    transition: max-height 2s .2s cubic-bezier(1, 0.32, 0.68, 0.15);
  }
}

.nav--inline {
    display: flex;
    align-items: center;
    align-content: center;
    @include respond-to(small) {
        height: 20vh;
    }
    @include respond-to(large) {
        height: 10vh;
    }
}

.admin-bar {
  background: #2b2b2b;
  width: 100%;
  overflow: hidden;
}

.navbar-right {
  a {
    color: white;
    &:hover {
      color: black;
    }
  }
}
