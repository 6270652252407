body > header {
  background: #fff;
  @include respond-to(small) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  // @include respond-to(medium) {
  //   display: flex;
  //   justify-content: space-between;
  //   flex-direction: row-reverse;
  // }
}

.logo--main {
  // height: 48px;
  // width: auto;
  width: 300px;
  @include respond-to(small) {
    width: 200px;
  }
  @include respond-to(medium) {
    width: 250px;
  }
}
